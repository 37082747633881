var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":""}},[_c('b-table',{staticClass:"text-left",attrs:{"responsive":"sm","small":"","id":"my-table","hover":"","striped":"","fields":_vm.fields,"items":_vm.items,"per-page":10,"current-page":0,"busy":_vm.isBusy,"show-empty":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"p-2"},[_c('b-alert',{attrs:{"show":"","variant":"primary"}},[_c('p',{staticClass:"text-center"},[_c('br'),_vm._v(" Não existe "),_c('strong',[_vm._v("Evento")]),_vm._v(" para a semana escolhida, tente mudar a data buscar novos resultados! ")]),_c('hr')])],1)]},proxy:true},{key:"cell(title)",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.title))]),_c('br'),_c('small',[_vm._v(" "+_vm._s(item.notes ? 'Obs.: ' + item.notes : 'Sem descrição'))])]}},{key:"cell(project)",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v("#"+_vm._s(item.project.project_code))]),_c('p',[_vm._v(_vm._s(item.project.project_name))])]}},{key:"cell(actions)",fn:function(props){return [_c('div',{on:{"click":function($event){return _vm.handleUpdateModal(props.item)}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.lefttop.v-primary",modifiers:{"hover":true,"lefttop":true,"v-primary":true}}],attrs:{"title":"Editar fornecedor","icon":"EditIcon","size":"15"}}),_vm._v("Editar ")],1)]}},{key:"cell(username)",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',{style:({
            display: 'flex',
            alignItems: 'center'
          })},[_c('span',{style:({
              borderRadius: '50%',
              //   display: 'block',
              background: item.member.color,
              width: '10px',
              height: '10px'
            })}),_c('p',{style:({
              height: 'auto',
              paddingLeft: '5px',
              paddingTop: '10px'
            })},[_vm._v(" "+_vm._s(item.member.access.user.username)+" ")])]),_c('small',[_vm._v(_vm._s(item.member.access.user.email))])])]}},{key:"cell(date)",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormatter(item.date, 'dd/MM/yyyy'))+" ")]}},{key:"cell(start)",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.start.slice(0, 5))+" ")]}},{key:"cell(end)",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.end.slice(0, 5))+" ")]}},{key:"cell(comments)",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.notes)+" ")]}}])}),_c('b-pagination',{staticClass:"justify-content-center",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"aria-controls":"my-table"},on:{"change":_vm.getPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('div',{staticClass:"mr-2 d-flex justify-content-end"},[_c('strong',[_vm._v("Total de Tarefas: "+_vm._s(_vm.totalRows)+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }